.App {
  text-align: center;
}
body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: white; */
  /* padding: 20px; */
  z-index: 1000;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}

.modal img {
  /* max-width: 100%; */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.custom-select {
  /* Basic styling */
  display: block;
  width: 100%;
  /* color: #4a5568; Tailwind color for text-gray-700 */
  border: 1px solid #e2e8f0; /* Tailwind color for border-gray-200 */
  border-radius: 0.5rem; /* Rounded corners */
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 12px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.custom-select:focus {
  outline: none;
  border-color: #a0aec0; /* Tailwind color for focus:border-gray-500 */
  background-color: #fff; /* Focus background color */
}

.custom-select:hover {
  border-color: #cbd5e0; /* Tailwind color for hover:border-gray-300 */
}

.custom-option {
  padding: 12px;
  background-color: #f9fafb; /* Background for the dropdown options */
  color: #4a5568; /* Text color */
}

/* Custom dropdown arrow */
#grid-state-qu::-ms-expand {
  display: none;
}