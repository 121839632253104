.main {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.up {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.down {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.card1 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    border-radius: 90px 5px 5px 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instagram {
    margin-top: 1.5em;
    margin-left: 1.2em;
    fill: #cc39a4;
}

.card2 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    border-radius: 5px 90px 5px 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.facebook {
    margin-top: 1.5em;
    margin-left: -.9em;
    fill: #03A9F4;
}

.card3 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    border-radius: 5px 5px 5px 90px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.youtube {
    margin-top: -.6em;
    margin-left: 1.2em;
    fill: #f71a0a;
}

.card4 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    border-radius: 5px 5px 90px 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

}

.tiktok {
    margin-top: -.9em;
    margin-left: -1.2em;
    fill: #f14336;
}

.card1:hover {
    cursor: pointer;
    scale: 1.1;
    background-color: #cc39a4;
}

.card1:hover .instagram {
    fill: white;
}

.card2:hover {
    cursor: pointer;
    scale: 1.1;
    background-color: #1877f2;
}

.card2:hover .facebook {
    fill: white;
}

.card3:hover {
    cursor: pointer;
    scale: 1.1;
    background-color: #d60004;
}

.card3:hover .youtube {
    fill: white;
}

.card4:hover {
    cursor: pointer;
    scale: 1.1;
    background-color: #FF0004;
}

.card4:hover .tiktok {
    fill: white;
}