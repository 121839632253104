@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-secondary text-[18px] text-secondary;
}

a {
  @apply text-[18px];
}

/* scroll */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #dcdbdb;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #dcdbdb;
}