.testimonial-card {
    margin: 0 10px;
}

.testimonial-card {
    max-height: 400px;
    overflow-y: auto;
}

.testimonial-card p {
    max-height: 600px;
    overflow-y: auto;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}